import { Country } from '@bp/shared/models/countries';

import { ApiMockPlugin } from '@bp/frontend/api-mocking';
import { GEOLOCATION_PATH_SEGMENT, GeolocationInfoDTO } from '@bp/frontend/features/geolocation/models';
import { CF_WORKER_PATH_SEGMENT } from '@bp/frontend/services/http';

export default class GlobalApiMockPlugin extends ApiMockPlugin {
	constructor() {
		super({
			routes() {
				const cloudflareNamespace = `/${ CF_WORKER_PATH_SEGMENT }`;

				this.get(
					`${ cloudflareNamespace }/${ GEOLOCATION_PATH_SEGMENT }`,
					() => <GeolocationInfoDTO>{
						ip: '127.0.0.1',
						country: new Country('CY'),
						city: 'Limassol',
						continent: 'EU',
						region: 'Limassol District',
						timezone: 'Asia/Nicosia',
						isEUCountry: true,
					},
				);

				return [ cloudflareNamespace ];
			},
		});
	}
}
